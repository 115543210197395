
import { Component, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import TheReport from "@/components/TheReport.vue";
import * as LeadServices from "@/api/helpers/Lead";
import Notify from "@/utils/notifications";
import Lead from "@/models/Lead";
import _ from "underscore";
import * as SatServices from "@/api/helpers/Sat";
import * as DebtServices from "@/api/helpers/Debt";
import * as StateServices from "@/api/helpers/State";
import * as LenderServices from "@/api/helpers/Lender";
import * as IncomeServices from "@/api/helpers/Income";
import * as SystemServices from "@/api/helpers/System";
import * as DepositServices from "@/api/helpers/Deposit";
import * as MaxPriceServices from "@/api/helpers/MaxPrice";
import * as PhoneBrandServices from "@/api/helpers/PhoneBrand";
import * as ProfessionServices from "@/api/helpers/Profession";
import * as VehicleSizeServices from "@/api/helpers/VehicleSize";
import * as VehicleTypeServices from "@/api/helpers/VehicleType";
import * as PhoneCompanyServices from "@/api/helpers/PhoneCompany";
import * as CompanyYearsServices from "@/api/helpers/CompanyYears";
import * as MonthlyPaymentServices from "@/api/helpers/MonthlyPayment";
import * as CompanyActivityServices from "@/api/helpers/CompanyActivity";
import * as CompanyEmployeesServices from "@/api/helpers/CompanyEmployees";
import * as DedicatedTimeServices from "@/api/helpers/DedicatedTime";
import * as InvestmentServices from "@/api/helpers/Investment";
import * as MotivationServices from "@/api/helpers/Motivation";
import * as SchoolGradeServices from "@/api/helpers/SchoolGrade";
import * as StudiesFieldServices from "@/api/helpers/StudiesField";
import * as ColumnService from "@/api/helpers/Column";

@Component({
  components: {
    DeletePopover,
    TheReport,
  },
})
export default class LeadView extends Vue {
  fullscreenLoading = true;
  report = false;
  searchingToday = false;
  todayStart = new Date(new Date().setHours(0, 0, 0, 0));
  todayEnd = new Date(new Date().setHours(23, 59, 59, 0));
  defaultParams = {
    order: "createdAt:desc",
    limit: 10,
    offset: 0,
    birthdate: [],
    createdAt: [this.todayStart, this.todayEnd],
    // identicalEmails: null,
    // validEmail: null,
    // registered: null,
    // identicalPhones: null,
    checkIncome: null,
    debit: null,
    credit: null,
    paymentUpToDate: null,
    creditPaymentUpToDate: null,
    completed: null,
    email: "",
    phone: "",
    income: null,
    state: "",
    debt: "",
    lender: "",
    phoneBrand: "",
    phoneCompany: "",
    profession: "",
    system: "",
    afid: "",
    deposit: null,
    maxprice: null,
    vehiclesize: null,
    vehicletype: null,
    monthlypayment: null,
    sat: null,
    companyactivity: null,
    companyemployees: null,
    companyyears: null,
    companyOwner: null,
    dedicatedTime: null,
    motivation: null,
    Investment: null,
    companyName: "",
    vehiclePaymentMethod: "",
    schoolGrade: false,
    studiesField: false,
  };
  params: {
    [key: string]: any;
  } = _.clone(this.defaultParams);
  leads: Array<Lead> = [];
  sats = [];
  debts = [];
  states = [];
  lenders = [];
  systems = [];
  incomes = [];
  deposits = [];
  maxPrices = [];
  professions = [];
  phoneBrands = [];
  vehicleSizes = [];
  vehicleTypes = [];
  companyYears = [];
  dedicatedTime = [];
  investment = [];
  motivation = [];
  phoneCompanies = [];
  monthlyPayments = [];
  companyEmployees = [];
  companyActivities = [];
  schoolGrade = [];
  studiesField = [];
  currentPage = 1;
  limitArray = [10, 25, 50, 100, 250, 500, 1000];
  availableColumns: Array<Record<string, any>> = [];
  selectedColumns: Array<string> = [];
  defautlColumns = [
    {
      code: "email",
      name: "Correo electrónico",
      disabled: false,
      systems: [-1],
    },
    {
      code: "createdAt",
      name: "Fecha de registro",
      disabled: false,
      systems: [-1],
    },
    {
      code: "id",
      name: "Id",
      disabled: false,
      systems: [-1],
    },
    {
      code: "system",
      name: "Sistema",
      disabled: false,
      systems: [-1],
    },
    {
      code: "completed",
      name: "Solicitud completa",
      disabled: false,
      systems: [-1],
    },
    {
      code: "phone",
      name: "Teléfono",
      disabled: false,
      systems: [-1],
    },
  ];
  searchedColumns: Array<string> = [];
  loadedCatalogs: any = {};

  async setColumns() {
    const result = await ColumnService.find({
      order: "name:asc",
    });
    if (result.data.code === "OK") {
      // eslint-disable-next-line prefer-const
      let codes = _.pluck(this.defautlColumns, "code");
      const permitedIds = process.env.VUE_APP_COLUMN_IDS?.split(",").map(
        Number
      );
      for (const column of result.data.data) {
        if (
          codes.lastIndexOf(column.code) === -1 ||
          permitedIds?.some(id => id === column.id)
        ) {
          codes.push(column.code);
          this.availableColumns.push({
            code: column.code,
            name: column.name,
            disabled: false,
            systems: _.pluck(_.pluck(column.systems, "System"), "id"),
          });
        }
      }
    }
    this.availableColumns = this.availableColumns.concat(this.defautlColumns);
    this.availableColumns = _.sortBy(this.availableColumns, "name");
    this.selectedColumns = _.pluck(
      _.filter(this.defautlColumns, x => {
        return !x.disabled;
      }),
      "code"
    );
  }

  getLeads(page = this.currentPage) {
    this.currentPage = page;
    this.fullscreenLoading = true;
    this.params.offset = (page - 1) * this.params.limit;
    LeadServices.find(this.formatParams())
      .then(res => {
        this.searchedColumns = this.selectedColumns;
        this.leads = res.data.data;
        this.searchingToday =
          JSON.stringify(this.params.createdAt) ==
          JSON.stringify(this.defaultParams.createdAt);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  deleteLead(id: number) {
    this.fullscreenLoading = true;
    LeadServices.destroy(id)
      .then(res => {
        this.getLeads(1);
        Notify.successful(`${res.data.data.email} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.fullscreenLoading = false;
      });
  }

  clearSearch() {
    this.params = _.clone(this.defaultParams);
    // this.availableColumns = this.availableColumns.concat(this.defautlColumns);
    // this.availableColumns = _.sortBy(this.availableColumns, "name");
    this.selectedColumns = _.pluck(
      _.filter(this.defautlColumns, x => {
        return !x.disabled;
      }),
      "code"
    );
    this.getLeads(1);
  }

  formatParams() {
    const params = this.params;
    const columns = _.without(this.selectedColumns, "id");
    params.columns = `id,${columns.join()}`;
    return _.pick(params, Boolean);
  }

  sortLeads(params: any) {
    const ENDING = "ending";
    this.params.order = params.order
      ? `${params.prop}:${params.order.replace(ENDING, "")}`
      : this.defaultParams.order;
    this.getLeads();
  }

  chargeCatalogs(column: string, exist: boolean) {
    switch (column.toLowerCase()) {
      case "sat":
        if (exist && !this.loadedCatalogs[column]) {
          this.getSats();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "debt":
        if (exist && !this.loadedCatalogs[column]) {
          this.getDebts();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "state":
        if (exist && !this.loadedCatalogs[column]) {
          this.getStates();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "lender":
        if (exist && !this.loadedCatalogs[column]) {
          this.getLenders();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "income":
        if (exist && !this.loadedCatalogs[column]) {
          this.getIncomes();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "deposit":
        if (exist && !this.loadedCatalogs[column]) {
          this.getDeposits();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "maxprice":
        if (exist && !this.loadedCatalogs[column]) {
          this.getMaxPrices();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "investment":
        if (exist && !this.loadedCatalogs[column]) {
          this.getInvestment();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "motivation":
        if (exist && !this.loadedCatalogs[column]) {
          this.getMotivation();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "phonebrand":
        if (exist && !this.loadedCatalogs[column]) {
          this.getPhoneBrands();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "profession":
        if (exist && !this.loadedCatalogs[column]) {
          this.getProfessions();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "schoolgrade":
        if (exist && !this.loadedCatalogs[column]) {
          this.getSchoolGrade();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "vehiclesize":
        if (exist && !this.loadedCatalogs[column]) {
          this.getVehicleSizes();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "vehicletype":
        if (exist && !this.loadedCatalogs[column]) {
          this.getVehicleTypes();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "studiesfield":
        if (exist && !this.loadedCatalogs[column]) {
          this.getStudiesField();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "companyyears":
        if (exist && !this.loadedCatalogs[column]) {
          this.getCompanyYears();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "dedicatedtime":
        if (exist && !this.loadedCatalogs[column]) {
          this.getDedicatedTime();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "monthlypayment":
        if (exist && !this.loadedCatalogs[column]) {
          this.getMonthlyPayments();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "companyemployees":
        if (exist && !this.loadedCatalogs[column]) {
          this.getCompanyEmployees();
          this.loadedCatalogs[column] = true;
        }
        break;
      case "companyactivity":
        if (exist && !this.loadedCatalogs[column]) {
          this.getCompanyActivities();
          this.loadedCatalogs[column] = true;
        }
        break;
      default:
        break;
    }
  }

  inSelectedColumns(column: string) {
    if (
      this.selectedColumns.includes("name") &&
      !this.selectedColumns.includes("paternalSurname") &&
      !this.selectedColumns.includes("maternalSurname")
    ) {
      this.selectedColumns.push("paternalSurname");
      this.selectedColumns.push("maternalSurname");
    }
    const lowSelectedColumns = _.map(this.selectedColumns, c => {
      return c.replace(/Id$/g, "").toLowerCase();
    });

    const exist = _.contains(lowSelectedColumns, column.toLowerCase());

    this.chargeCatalogs(column, exist);

    if (!exist) this.params[column] = null;
    return exist;
  }

  selectAllColumns() {
    this.selectedColumns = _.pluck(
      _.filter(this.availableColumns, x => {
        return !x.disabled;
      }),
      "code"
    );
    _.each(this.selectedColumns, c => this.chargeCatalogs(c, true));
  }

  inSearchedColumns(column: string) {
    const lowSearchedColumns = _.map(this.searchedColumns, c => {
      return c.replace(/Id$/g, "").toLowerCase();
    });
    return _.contains(lowSearchedColumns, column.toLowerCase());
  }

  closeDialog(value: boolean) {
    this.report = value;
  }

  getSystems() {
    SystemServices.find({
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.systems = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getStates() {
    StateServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.states = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getIncomes() {
    IncomeServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.incomes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getProfessions() {
    ProfessionServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.professions = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getDebts() {
    DebtServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.debts = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getLenders() {
    LenderServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.lenders = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getPhoneBrands() {
    PhoneBrandServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.phoneBrands = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getPhoneCompanies() {
    PhoneCompanyServices.find({
      order: "name:asc",
    })
      .then(res => {
        this.phoneCompanies = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getDeposits() {
    DepositServices.find()
      .then(res => {
        this.deposits = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getMaxPrices() {
    MaxPriceServices.find()
      .then(res => {
        this.maxPrices = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getMonthlyPayments() {
    MonthlyPaymentServices.find()
      .then(res => {
        this.monthlyPayments = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getVehicleSizes() {
    VehicleSizeServices.find()
      .then(res => {
        this.vehicleSizes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getVehicleTypes() {
    VehicleTypeServices.find()
      .then(res => {
        this.vehicleTypes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getSats() {
    SatServices.find()
      .then(res => {
        this.sats = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCompanyActivities() {
    CompanyActivityServices.find()
      .then(res => {
        this.companyActivities = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCompanyEmployees() {
    CompanyEmployeesServices.find()
      .then(res => {
        this.companyEmployees = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getDedicatedTime() {
    DedicatedTimeServices.find()
      .then(res => {
        this.dedicatedTime = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getInvestment() {
    InvestmentServices.find()
      .then(res => {
        this.investment = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getMotivation() {
    MotivationServices.find()
      .then(res => {
        this.motivation = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCompanyYears() {
    CompanyYearsServices.find()
      .then(res => {
        this.companyYears = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getSchoolGrade() {
    SchoolGradeServices.find()
      .then(res => {
        this.schoolGrade = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getStudiesField() {
    StudiesFieldServices.find()
      .then(res => {
        this.studiesField = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  created() {
    const index = "3.1";
    const title = "Leads";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: title,
      },
    ]);
    this.selectedColumns = _.map(this.defautlColumns, x => x.code);
    this.setColumns().then(() => {
      this.getLeads();
      this.getSystems();
    });
  }

  async updateColumns(event: Array<number>) {
    this.availableColumns.map(x => {
      let isValid = false;
      if (event.length > 0) {
        const sameSystems = event.filter(y => x.systems.lastIndexOf(y) > -1);
        isValid = sameSystems.length >= event.length;
      } else {
        isValid = true;
      }
      if (isValid || x.systems.lastIndexOf(-1) > -1) {
        x.disabled = false;
      } else {
        x.disabled = true;
        const index = this.selectedColumns.indexOf(x.code);
        if (index > -1) {
          this.selectedColumns.splice(index, 1);
        }
      }
    });
    if (event.length === 0) {
      this.selectedColumns = _.pluck(
        _.filter(this.defautlColumns, x => {
          return !x.disabled;
        }),
        "code"
      );
    }
  }
}
